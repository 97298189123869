<template>
    <div>
        <div>
            <div class="title">用印员工 <span style="float:right;margin-bottom:10px"> <el-button size="mini" type="primary"
                        @click="inviteClick()">邀请用印员工</el-button></span>
                            <div style="font-size:12px;color:#666666;display:block;margin-top:-5px;font-weight:normal;">*仅用印员工可被添加为印章的授权使用对象</div></div>
            <div>
                <div class="search-box">
                    <span>员工名称：</span>
                    <span> <el-input v-model="query.userName" placeholder="" size="mini"
                            class="handle-input"></el-input></span>
                    <span> <el-button size="mini" type="primary" icon="el-icon-search" :loading="isloading"
                            @click="handleSearch">查询
                        </el-button>
                        <el-button size="mini" plain icon="el-icon-refresh" @click="reset">重置</el-button></span>
                </div>

                <el-table :data="tableData" class="table mb20" size="mini" :cell-style="cellStyle">
                    <el-table-column type="index" label="序号" width="55" 
            :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="员工名称" align="center"></el-table-column>
                    <el-table-column prop="signInviteTime" label="添加时间" align="center"
                        :formatter="formatDateTime"></el-table-column>
                    <el-table-column prop="signEnable" label="状态" align="center"
                        :formatter="showSignEnable"></el-table-column>
                    <el-table-column label="操作" width="80" align="center" class="btn-text">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="logOff(scope.row.id)"
                                v-if="scope.row.signEnable === '1'">注销</el-button>
                            <el-button type="text" size="mini" @click="enable(scope.row.id)"
                                v-if="scope.row.signEnable === '2'">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination" v-if="tableData.length > 0">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="query.page" :page-sizes="[10, 20, 30, 50]" :page-size="query.size"
                        layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                    </el-pagination>
                </div>

            </div>
            <div v-if="editVisible">
                <EditInfo>
                </EditInfo>
            </div>

        </div>

    </div>
</template>
  
<script>
import EditInfo from './EditInfo'
export default {
    name: "useSealEmployees",
    data() {
        return {
            query: {
                userName: "",
                size: 10,
                page: 1,
                signInvite: 1
            },
            isloading: false,
            tableData: [],
            tableDataSon: [],
            editVisible: false,
            info: {
                company: "常州加佳旺路哦科技有限公司"
            },
            totalCount: 0
        };
    },
    components: {
        EditInfo,
    },
    async created() {

        this.getList();
    },
    mounted() {
        this.isloading = false;
    },
    methods: {

        getList() {


            let params = {
                process_id: '00000254', // 流程ID ---95是查询印章管理
                nodeInfo: {

                    userName: this.query.userName,
                    size: this.query.size,
                    page: this.query.page - 1,
                    signInvite: 1

                } // 传参  查询则是查询条件  可传可不传等
            }
            this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
                .then(result => {
                    const { succeed, data, errorMsg } = result
                    this.isloading = false;
                    if (succeed === 0) {
                        if (data?.content) {
                            this.tableData = data?.content
                            this.totalCount = data?.total
                        }
                    } else {

                        this.$message.error(errorMsg)
                    }

                })
                .catch(err => { this.isloading = false; })


        },
        // 重置
        reset() {
            this.query = {
                userName: "",

                size: 10,
                page: 1,
                signInvite: 1

            };
            this.getList();
        },
        // 触发搜索按钮
        handleSearch() {
            this.isloading = true
            this.query.page = 1
            this.getList()
        },


        // 改变页码
        handleSizeChange(val) {
            this.query.size = val
            this.getList()
        },
        // 改变当前页
        handleCurrentChange(val) {
            this.query.page = val
            this.getList()
        },
        logOff(id) {
            this.$confirm('注销后，该员工将无法继续使用电子签章，是否继续？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    let params = {
                        process_id: '00000257', // 流程ID ---注销
                        nodeInfo: { id: id } // 传参  
                    }
                    this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
                        .then(result => {
                            const { succeed, data, errorMsg } = result
                            if (succeed === 0) {
                                this.$message.success('注销成功！')
                                this.getList()
                            }
                        })
                        .catch(err => { })
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        enable(id) {
            this.$confirm('确定启用该员工？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    let params = {
                        process_id: '00000259', // 流程ID ---启用
                        nodeInfo: { id: id } // 传参  
                    }
                    this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
                        .then(result => {
                            const { succeed, data, errorMsg } = result
                            if (succeed === 0) {
                                this.$message.success('启用成功！')
                                this.getList()
                            }
                        })
                        .catch(err => { })
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        inviteClick() {
            this.getUser()

        },
        getUser() {
            let params = {
                process_id: '00000255', // 流程ID ---查询用户列表
                nodeInfo: {} // 传参  
            }
            this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
                .then(result => {
                    const { succeed, data, errorMsg } = result
                    if (succeed === 0) {
                        if (data?.content) {
                            this.userOptions = data?.content

                        }
                        this.editVisible = true
                    }
                })
                .catch(err => { })
        },
        showSignEnable(row) {
            if (row.signEnable === "0") {
                return "待认证"
            } else if (row.signEnable === "1") {

                return "已认证"
            } else if (row.signEnable === "2") {
                return "已注销"
            }
        },

        formatDateTime(row) {
            if (!row.signInviteTime) return '';
            var date = new Date(row.signInviteTime); //将时间戳转换成日期对象
            console.log(row.signInviteTime)
            var year = date.getFullYear();  //获取年
            var month = ('0' + (date.getMonth() + 1)).slice(-2); //获取月
            var day = ('0' + date.getDate()).slice(-2);//获取日
            var hours = ('0' + date.getHours()).slice(-2);//获取时
            var minutes = ('0' + date.getMinutes()).slice(-2);//获取分
            var seconds = ('0' + date.getSeconds()).slice(-2);//获取秒
            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        },

        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (column.property === "signEnable") {
                // {name: "signEnable", code: 1, desc: "已认证"}
                // {name: "signEnable", code: 2, desc: "已注销"}
                // {name: "signEnable", code: 0, desc: "待认证"}
                switch (row?.signEnable) {
                    case "1":
                        return {
                            color: "#67C23A",
                            fontWeight: "bolder",
                        };
                        break;
                    case "2":
                        return {
                            color: "#ED783F",
                            fontWeight: "bolder",
                        };
                        break;
                    case "0":
                        return {
                            color: "#000",
                            fontWeight: "bolder",
                        };
                        break;
                    default:
                        return {
                            color: "rgba(0,0,0,0.85)",
                            fontWeight: "bolder",
                        };
                        break;
                }
            }
        },
        
    indexMethod (index) {
      return index + (this.query.size * (this.query.page - 1)) + 1
    },
    },
};
</script>
  
<style scoped>
.title {
    border-bottom: 3px solid #1d74e0;
    height: 50px;
    line-height: 27px;
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    padding-left: 30px;
}

.handle-input {
    width: 120px;
    margin-right: 10px;
}

.form-info {
    margin-top: 20px;
}

.table {
    width: 100%;
    /* font-size: 14px; */
    margin-top: 20px;
}


.mr10 {
    margin-right: 10px;
}

.mb20 {
    margin-bottom: 20px;
}



.search-box {
    margin-top: 20px;
    font-size: 14px;
    font-weight: normal;
    color: #606266;
}

.search-box span {
    margin-right: 10px;
}

.el-button--text {
    color: #f00
}
</style>