<template>
    <div v-if="$parent.editVisible">
        <!-- 编辑弹出框 -->
        <el-dialog :close-on-click-modal="false" :title="stepTitle" :visible.sync="$parent.editVisible" width="450px"
            @close="resetForm()" class="dialog-class">
            <el-form ref="form" :rules="rules" label-position="right" :model="form" size="small" label-width="110px"
                class="form-seal" @submit.native.prevent>
                <el-form-item prop="userId" label="选择员工：" v-if="stepTitle === '邀请员工'">
                    <el-select size="mini" v-model="form.userId" placeholder="选择员工">
                        <el-option :label="item.name" :value="item.id" v-for="item in $parent.userOptions" :key="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item prop="realName" label="员工真实姓名：" v-if="stepTitle === '邀请员工'">
                    <el-input v-model="form.realName" size="mini" maxlength="10" show-word-limit>
                    </el-input>
                </el-form-item> -->
                <el-form-item prop="company" label="企业认证名称：" v-if="stepTitle === '邀请员工'" >
                    <span style="font-size:12px">{{ fullNameOfEnterprise }}</span>

                </el-form-item>
                <el-form-item prop="address" label="邀请地址：" v-if="stepTitle === '邀请链接'">
                    <span style="color:#7AB6F0">{{ form.address }}</span>
                </el-form-item>
                <el-form-item prop="" label="提示：" v-if="stepTitle === '邀请链接'"   class="label-cls">
                    <span style="color:#666666">请将以上链接发送给受邀人，通过链接完成实名认证后，可成为企业用印员工。</span>
                </el-form-item>


            </el-form>
            <span slot="footer">
                <el-button type="primary" size="small" @click="nextStep" :loading="isloading"
                    v-if="stepTitle === '邀请员工'">下一步</el-button>
                <el-button type="primary" size="small" @click="saveEdit" :loading="isloading"
                    v-if="stepTitle === '邀请链接'">复制信息</el-button>
                <el-button size="small" @click="resetForm()" type="info">{{ stepTitle === '邀请员工' ? '取 消' : '关 闭'
                }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    name: 'EditInfo',

    data() {

        return {
            isloading: false,
            stepTitle: "邀请员工",

            rules: {
                userId: [{ required: true, message: '请选择员工', trigger: 'change' }],
                // realName: [{ required: true, message: '请输入员工真实姓名', trigger: 'blur' }],
            },
            form: {
            }
        }
    },

    mounted() {
    },
    computed: {
        ...mapState({
            fullNameOfEnterprise: state => state.Ylt.fullNameOfEnterprise
        }),
    },
    methods: {

        // 下一步
        nextStep() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let params = {
                        process_id: '00000256', // 流程ID ---获取邀请链接
                        nodeInfo: { id: this.form.userId } // 传参  
                    }
                    this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
                        .then(result => {
                            const { succeed, data, errorMsg } = result
                            if (succeed === 0) {
                                if (data?.memberActiveEmbedUrl) {
                                    this.stepTitle = "邀请链接"
                                    this.$set(this.form, "address", data.memberActiveEmbedUrl)
                                }
                                this.editVisible = true
                            } else {

                            }

                        })
                        .catch(err => { })
                } else {
                    return false
                }
            })





        },
        // 保存编辑
        saveEdit() {
            let textToCopy = "加佳数字供应链管理平台邀请您通过第三方电子签章渠道完成实名认证，以获取签章授权：" + this.form.address
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                        this.$message.success("复制成功");
                    })
                    .catch(() => {
                        this.$message.error("复制失败");
                    })
            } else {
                // 创建text area
                const textArea = document.createElement('textarea')
                textArea.value = textToCopy
                textArea.readOnly = "readOnly"
                textArea.position = "absolute"
                textArea.left = "-99999px"
                // 使text area不在viewport，同时设置不可见
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()
                try {
                    const res = document.execCommand('copy')
                    if (res) {
                        this.$message.success("复制成功");
                    } else {
                        this.$message.error("复制失败");
                    }
                } catch (error) {
                    this.$message.error("复制失败");

                }
            }

        },
        // 重置信息
        resetForm() {
            if(this.form.address){
                this.$parent.getList()
            }
            this.form = {}
            this.stepTitle = "邀请员工"
            this.$parent.editVisible = false
           

        },

    }
}
</script>
  
<style scoped>
.mr10 {
    margin-right: 10px;
}

.el-input,
.el-select {
    width: 250px;
}

.dialog-class /deep/.el-dialog__footer {
    text-align: center;
}

.el-button+.el-button {
    margin-left: 50px;
}
.label-cls /deep/ .el-form-item__label{
    color:#666666 
}
</style>
  